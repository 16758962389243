import './App.scss';
import ReactGA from 'react-ga4';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Header from './components/Header';
import Popup from './components/Popups/Popup';
import HowToPlay from './components/Popups/HowToPlay';
import Stats from './components/Popups/Stats';
import HintPanel from './components/HintPanel';
import Game from "./lib/game";
import Page from "./Page";

import songs from './data/songs.json';
import games from './data/games.json';
import TrackPlayer from './components/TrackPlayer';
import GuessBar from './components/GuessBar';
import Results from './components/Results';

const TRACKING_ID = "G-QFXKCX4N3S";

function App() {

  let [gameStats, gameHistory] = Game.LoadGameData();

  // Game Variables
  const [gameStatus, setGameStatus] = useState({});
  const [currentSong, setCurrentSong] = useState(undefined);
  const [currentGame, setCurrentGame] = useState(undefined);
  const [currentDay, setCurrentDay] = useState(undefined);
  const [currentStep, setCurrentStep] = useState(0);
  const [hasFinished, setHasFinished] = useState(false);
  const [canGuess, setCanGuess] = useState(false);
  const [hasYear, setHasYear] = useState([]);
  const [hasConsole, setHasConsole] = useState([]);
  const [hasSeries, setHasSeries] = useState([]);
  const [franchises, setFranchises] = useState([]);
  const [stopPlaying, setStopPlaying] = useState(-1);
  const [searchParams] = useSearchParams();
  const [showEndingPopup, setShowEndingPopup] = useState(false);

  const proposedDay = parseFloat(searchParams.get("day")) ?? 0;

  // Event Handlers
  function OnShareClick(){
    Game.ShareGame(gameStatus, gameStats);
    toast('Copied to clipboard!');
  }

  // Get the current song and game
  useEffect(() => {
    Game.GetDailySong(proposedDay, (song, game, unixtime) => {
      let day = Game.GetBandleDay(unixtime);
      if(proposedDay > 0 && (proposedDay <= day || process.env.NODE_ENV === "development")){
        day = proposedDay;
      }
      let status = gameHistory[day] ?? Game.GetDefaultStatus(day);
      setCurrentSong(song);
      setCurrentGame(game);
      setCurrentDay(day);
      setGameStatus(status);
      setCurrentStep(status.step);
      setHasFinished(status.win || status.lose);
      setCanGuess(false);
      setHasYear(status.hasYear);
      setHasConsole(status.hasConsole);
      setHasSeries(status.hasSeries);
      setFranchises(status.franchises);
    });
  }, []);

  function Guess(guessId) {
    const guessSong = songs.find(song => song.folder === guessId);
    if(guessId === currentSong.folder)
    {
      EndGame(true); 
    }
    else
    {
      const guessGame = games.find(game => game.id === guessSong.game);
      if(guessGame.released === currentGame.released)
      {
        gameStatus.hasYear = [...hasYear, currentStep]
        setHasYear(gameStatus.hasYear);
      }
      if(guessGame.platform === currentGame.platform)
      {
        gameStatus.hasConsole = [...hasConsole, currentStep]
        setHasConsole(gameStatus.hasConsole);
      }
      if(guessGame.franchises.some(f => currentGame.franchises.includes(f)))
      {
        const matchingFranchises = currentGame.franchises.filter(f => guessGame.franchises.includes(f));
        const newFranchises = matchingFranchises.filter(f => !franchises.includes(f));
        if(newFranchises.length > 0){
          gameStatus.franchises = [...franchises, ...newFranchises];
          setFranchises(gameStatus.franchises);
          gameStatus.hasSeries = [...hasSeries, currentStep];
          setHasSeries(gameStatus.hasSeries);
        }
      }
      if(currentStep === 5){
        UpdateStatus(guessId, currentStep + 1);
        EndGame(false);
      }else{
        UpdateStatus(guessId, currentStep + 1);
        setCurrentStep(currentStep + 1);
        setCanGuess(false);
        setStopPlaying(currentStep);
      }
    }

    ReactGA.event("guess", {
      category: "Game",
      action: "Guess",
      label: guessId,
      value: currentStep + 1
    });
  }

  function Skip() {
    if(currentStep >= 5){
      EndGame(false);
    }

    UpdateStatus("", currentStep + 1);
    setCurrentStep(currentStep + 1);
    setCanGuess(false);
    setStopPlaying(currentStep);

    ReactGA.event("skip", {
      category: "Game",
      action: "Skip",
      value: currentStep + 1
    });
  }

  function EndGame(didWin)
  {
    if(didWin)
    {
      gameStats.played++;
      gameStats.currentStreak++;
      gameStats.maxStreak = Math.max(gameStats.maxStreak, gameStats.currentStreak);
      gameStats["guess" + (currentStep + 1)]++;
    }
    else
    {
      gameStats.lost++;
      gameStats.currentStreak = 0;
    }

    localStorage.setItem("statistics", JSON.stringify(gameStats));

    gameStatus.win = didWin;
    gameStatus.lose = !didWin;
    gameHistory[currentDay] = gameStatus;
    localStorage.setItem("history", JSON.stringify(gameHistory));
    setHasFinished(true);

    ReactGA.event((didWin ? "win" : "loss"), {
      category: "Game",
      action: (didWin ? "Win" : "Loss"),
      value: currentStep + 1
    });
  }

  function UpdateStatus(guess, step)
  {
    gameStatus.step = step;
    gameStatus.guesses.push(guess);
    gameHistory[currentDay] = gameStatus;
    localStorage.setItem("history", JSON.stringify(gameHistory));
  }

  return (currentSong ? (
    <Page endedPopup={showEndingPopup} setEndedPopup={setShowEndingPopup} >
      {/* <div className="info-title">Guess the video game and song played by the MIDI band</div> */}
      <div className="info-panel">
        <div className="info-text">VGBandle has officially <span className="ended-link" onClick={() => setShowEndingPopup(true)}>ended</span>. The daily will continue to loop previous songs but no new entries will be added.</div>
      </div>
      <HintPanel song={currentSong} game={currentGame} showInfo={currentStep >= 5 || hasFinished}
        hasYear={hasYear.length > 0}
        hasConsole={hasConsole.length > 0}
        hasFranchises={franchises} />
      <TrackPlayer song={currentSong} game={currentGame}
        step={currentStep}
        hasFinished={hasFinished}
        hasYear={hasYear}
        hasConsole={hasConsole}
        hasSeries={hasSeries}
        guesses={gameStatus.guesses}
        canGuess={canGuess} setCanGuess={setCanGuess}
        stopPlaying={stopPlaying} setStopPlaying={setStopPlaying} />
      {hasFinished ? (
        <div className="results">
          <Results didWin={gameStatus.win} song={currentSong} stats={gameStats} OnShareClick={OnShareClick} />
          <p>Check out the original <a href="https://bandle.app/">Bandle</a></p>
          {/* <p>Click <a href="https://forms.gle/66nsnTWJB1Asj8T4A">here</a> to submit songs!</p> */}
        </div>
      ) : ""}
      {(canGuess && !hasFinished) ? (
        <GuessBar OnGuess={Guess} OnSkip={Skip} />
      ) : ""}
    </Page>
  ) : "");
}

export default App;
