import './Page.scss';
import ReactGA from 'react-ga4';
import { useState, useEffect } from 'react';

import Header from './components/Header';
import Popup from './components/Popups/Popup';
import HowToPlay from './components/Popups/HowToPlay';
import Stats from './components/Popups/Stats';
import Game from "./lib/game";
import { useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const TRACKING_ID = "G-QFXKCX4N3S";

function Page(props) {

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    // Send pageview event
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, [])

  // Game Stats
  let [gameStats, gameHistory] = Game.LoadGameData();
  const [searchParams] = useSearchParams();
  const proposedDay = parseFloat(searchParams.get("day")) ?? 0;

  // Popup Variables
  const [currentDay, setCurrentDay] = useState(0);
  const [helpPopup, setHelpPopup] = useState(false);
  const [statsPopup, setStatsPopup] = useState(false);
  const [didFirstTimePopup, setDidFirstTimePopup] = useState(false);

  // Event Handlers
  let OnHelpClick = () => setHelpPopup(true);
  let OnStatsClick = () => setStatsPopup(true);
  let OnShareClick = () => {
    Game.ShareGame(gameHistory[currentDay] ?? Game.GetDefaultStatus(currentDay), gameStats);
    toast('Copied to clipboard!');
  }

  // Show the First Time Popup
  useEffect(() => {
    Game.GetDailySong(proposedDay, (song, game, unixtime) => {
      let day = Game.GetBandleDay(unixtime);
      if(proposedDay > 0 && proposedDay <= day){
        day = proposedDay;
      }
      setCurrentDay(day);
      let status = gameHistory[day] ?? Game.GetDefaultStatus(day);
      if(gameStats.played === 0 && status.step === 0 && !didFirstTimePopup)
      {
        setTimeout(() => {
          setHelpPopup(true);
          setDidFirstTimePopup(true);
        }, 1000);
      }
    });
  }, [])

  return (
    <div className="root-panel page">
      <Header OnShareClick={OnShareClick} OnStatsClick={OnStatsClick} OnHelpClick={OnHelpClick} />
      <div className="content">
        {props.children}
      </div>
      <Popup trigger={helpPopup} setTrigger={setHelpPopup}>
        <HowToPlay OnPlayClicked={() => setHelpPopup(false)} />
      </Popup>
      <Popup trigger={statsPopup} setTrigger={setStatsPopup}>
        <Stats stats={gameStats} />
      </Popup>
      <Popup trigger={props.endedPopup} setTrigger={props.setEndedPopup}>
        <div className="its-over">
          VGBandle was a fun little side-project I made over the summer.
          <div className="br" />
          I unfortunately don't have the time to keep it running, so I've decided to stop adding new songs.
          <div className="br" />
          The site will still show a new song every day, but it will be nothing but repeats from here on out.
          <div className="br" />
          If you're interested in anything else I'm working on you can go to my <a href="https://www.carsonk.net">website</a> or follow me on <a href="https://twitter.com/CarsonKompon">X/Twitter</a>.
          <div className="br" />
          Thanks for playing!
        </div>
      </Popup>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        />
    </div>
  );
}

export default Page;
